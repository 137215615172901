<template>
    <layout>
        <b-modal ref="contact-modal" centered body-bg-variant="light" v-model="modal">
            <template #modal-title>
                {{ $t('contact.add_contact_list') }}
            </template>
            <contact-form ref="contact-form" modal></contact-form>
            <template #modal-footer>
                <b-button class="btn-rounded px-3" size="sm" @click="$refs['contact-modal'].hide()">{{ $t('contact.close') }}</b-button>
                <b-button class="btn-rounded px-3" size="sm" variant="success" @click="$refs['contact-form'].save().then(() => $refs['contact-modal'].hide())">
                    <i class="fa fa-plus-square me-2"></i>
                    {{ $t('contact.add_contact_list') }}
                </b-button>
            </template>
        </b-modal>
        <div class="card">
            <div class="card-header">
                <div class="d-flex justify-content-between align-items-center">
                    <h4 class="card-title">{{ $t('contact.page_title') }}</h4>
                    <b-button block class="btn-rounded px-3" variant="success" size="sm" @click="modal = true;">
                        <i class="fas fa-plus-square me-2"></i>
                        {{ $t('contact.add_contact_list') }}
                    </b-button>
                </div>
            </div>
            <div class="card-body">
                <!-- Table -->
                <div class="table-responsive mb-0">
                    <b-table
                        :items="items"
                        :fields="fields"
                        responsive="sm"
                        :per-page="perPage"
                        :current-page="currentPage"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        hover
                        head-variant="light"
                        :busy="loading"
                        no-local-sorting
                    >
                        <template #table-busy>
                            <div class="text-center">
                                <div class="spinner-border text-primary" role="status">
                                    <span class="visually-hidden">{{ $t('table.loading') }}...</span>
                                </div>
                            </div>
                        </template>
                        <template #cell(actions)="data">
                            <b-button variant="dark" v-b-tooltip.hover.top="$t('table.edit')" size="sm" class="me-1" :to="$t('routes.contact_lists_edit').substr(0, $t('routes.contact_lists_edit').indexOf(':')) + data.item.m_id">
                                <i class="fas fa-pencil-alt"></i>
                            </b-button>
                            <b-button variant="danger" v-b-tooltip.hover.top="$t('table.delete')" size="sm" @click="destroy(data.item.m_id)">
                                <i class="fas fa-trash"></i>
                            </b-button>
                        </template>
                    </b-table>
                </div>
                <div class="row">
                    <div class="col">
                        <div
                            class="dataTables_paginate paging_simple_numbers float-end"
                        >
                            <ul class="pagination pagination-rounded mb-0">
                                <!-- pagination -->
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="rows"
                                    :per-page="perPage"
                                ></b-pagination>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from "../../layouts/table";
import {mapGetters} from "vuex";
import ContactForm from "@/views/pages/contact/form";
import Swal from "sweetalert2";

export default {
    name: "index",

    components: {ContactForm, Layout },

    data() {
        return {
            perPage: 10,
            currentPage: 1,
            sortBy: 'date',
            sortDesc: true,
            formatter: new Intl.NumberFormat('hu-HU', {
                style: 'currency',
                currency: 'HUF',
                maximumSignificantDigits: 1
            }),
            modal: false
        };
    },

    computed: {
        ...mapGetters({
            items: 'contact/list',
            item: 'contact/item',
            loading: 'contact/loading'
        }),

        rows: function () {
            return this.items && this.items.length
        },

        fields: function () {
            return [
                {
                    key: "name",
                    label: this.$t('contact.name'),
                    thClass: 'gridjs-th',
                    sortable: true
                },
                {
                    key: "actions",
                    label: '',
                    thClass: 'gridjs-th',
                    tdClass: 'text-end'
                },
            ];
        },
    },

    methods: {
        destroy: function (id) {
            Swal.fire({
                title: this.$t('table.confirm'),
                text: this.$t('table.confirm_delete'),
                icon: 'warning',
                showCancelButton: true,
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'btn btn-danger btn-md me-1',
                    cancelButton: 'btn btn-primary btn-md ms-1',
                    icon: 'text-danger border-danger'
                },
                confirmButtonText: this.$t('table.yes'),
                cancelButtonText: this.$t('table.no')
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch('contact/destroy', id).then(() => {
                        Swal.fire(
                            this.$t('request.delete_success'),
                            this.$t('table.selected_item_is_deleted'),
                            'success'
                        )
                    });
                }
            })
        },

        toPage: function (val) {
            this.page = val
            this.delayedFetch()
        },

        delayedFetch: function () {
            this.$store.dispatch('contact/fetchListDelayed', {
                limit: this.perPage,
                offset: this.pager && this.pager.offset,
                page: this.page,
                sorting: {
                    column: this.sortBy,
                    desc: this.sortDesc
                }
            });
        },
    },

    watch: {
        sortBy: function () {
            this.delayedFetch();
        },
        sortDesc: function () {
            this.delayedFetch();
        },
    },

    created() {
        document.title = this.setTitle(this.$t('contact.page_title'));
        this.$store.dispatch('contact/fetchList');
    }
}
</script>

<style scoped>

</style>
